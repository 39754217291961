//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CommonForm from "@/components/common/CommonForm";
import LazyFormRow from "@/components/common/LazyFormRow/index";
import popupWithForm from "@/mixins/popupWithForm";
import FormRecaptca from "~/components/common/LazyFormRow/components/components/FormRecaptca.vue";
export default {
    name: "SubscribeForm",
    mixins: [popupWithForm],
    components: {FormRecaptca, LazyFormRow, CommonForm},
    props: {
        source: {
            type: String,
            default: 'pop_up'
        }
    },
    methods: {
        successSubmit(result) {
            this.$ga.sendSimpleEvent('popup-subscription')
            let step = result.SubscriptionForm.code === 304 ? 3 : 2;
            this.$emit('changeStep', {
                step: step,
                message: result.SubscriptionForm.message
            })
            sessionStorage.setItem('canShowSubscribe', 2);
        },
        async errorSubmit(error) {
            if (error?.SubscriptionForm?.token) {
                this.hasCaptchaError = true;
                this.$store.commit(`forms/${ types.UPDATE_FORM_VALUE_BY_ID }`, {
                    formId: this.formId,
                    fieldName: 'token_type',
                    value: 'v2'
                });
            }
            if (!error) {
                this.hasCaptchaError = true;
            }
        },
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        privacyLink() {
            return this.lang === 'ru' ? '/politika-konfidenczialnosti-programmy-loyalnosti-adidas-universe' : '/politika-konfidenczijnosti-programi-loyalnosti-adidas-universe'
        },
    }
}
