import {mapGetters} from 'vuex'

export default {
    props: {
        formId: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            hasCaptchaError: false,
            captchaFieldName: 'token',
            captchaFieldTypeName: 'token_type'
        };
    },
    computed: {
        ...mapGetters({
            'getFormById': 'forms/getFormById',
            'getAllFormFieldsDataWithoutGrouping': 'forms/getAllFormFieldsDataWithoutGrouping'
        }),
        keyValueFormData() {
            return this.getAllFormFieldsDataWithoutGrouping(this.formId)
        },
        form () {
            return this.getFormById(this.formId)
        },
        fields () {
            return (this?.form?.fields ?? [])
        },
        fieldsStates() {
            return [...((this?.fields ?? []).reduce((acc, item) => {
                !acc.has(item.formName) && acc.add(item.formName);
                return acc
            }, new Set()))]
        },
    },
    methods: {
        onInput(newFieldData) {
            this.$store.commit(`forms/${types.UPDATE_FORM_FIELD_BY_ID}`, {
                formId: this.formId,
                fieldName: newFieldData.name,
                newFieldData: newFieldData
            })
        },
        successCaptcha(token) {
            this.$recaptcha.reset();
            this.hasCaptchaError = false;
            if (token && this.captchaFieldName) {
                this.$store.commit(`forms/${ types.UPDATE_FORM_VALUE_BY_ID }`, {
                    formId: this.formId,
                    fieldName: this.captchaFieldName,
                    value: token
                });
                this.$store.commit(`forms/${ types.UPDATE_FORM_VALUE_BY_ID }`, {
                    formId: this.formId,
                    fieldName: this.captchaFieldTypeName,
                    value: 'v2'
                });
            }
        },
        errorCaptcha() {
            this.hasCaptchaError = true;
        },
    },
}
