import Helpers from '~/utils/helpers';
import getServerCachedApiData from '~/utils/ServerApiCache/getServerCachedApiData';
import types from '~/store/mutation-types';
import isStringInListRegex from "~/assets/js/helper/isStringInListRegex";

export default async function(ctx) {
    // Check, if page not need SSR
    if (isStringInListRegex([
        "^\/size-guide([\/]{1}|$)",
        "^\/account([\/]{1}|$)",
    ], ctx.route.path)) {
        await getCombinedLocalizedData();
        return;
    }

    let redirected = false;
    const baseRequestParams = {};
    const sParam = (ctx?.route?.query?.s ?? '');
    sParam !== '' ? (baseRequestParams.s = sParam) : '';
    const pageParam = (ctx?.route?.query?.page ?? '');
    pageParam !== '' ? (baseRequestParams.page = pageParam) : '';

    const otherParams = {};
    const aff_sub = (ctx?.route?.query?.aff_sub ?? '');
    aff_sub !== '' ? (otherParams.aff_sub = aff_sub) : '';
    const utm_source = (ctx?.route?.query?.utm_source ?? '');
    utm_source !== '' ? (otherParams.utm_source = utm_source) : '';
    const utm_medium = (ctx?.route?.query?.utm_medium ?? '');
    utm_medium !== '' ? (otherParams.utm_medium = utm_medium) : '';
    const utm_campaign = (ctx?.route?.query?.utm_campaign ?? '');
    utm_campaign !== '' ? (otherParams.utm_campaign = utm_campaign) : '';
    const gclid = (ctx?.route?.query?.gclid ?? '');
    gclid !== '' ? (otherParams.gclid = gclid) : '';
    const uid = (ctx?.route?.query?.uid ?? '');
    uid !== '' ? (otherParams.uid = uid) : '';

    const routeInfo = await (async function getRouteData() {
        const requestQueryStr = Object.keys(baseRequestParams).reduce((acc, key, index) => {
            return index === 0 ?
                `${ acc }?${ key }=${ baseRequestParams[key] }` :
                `${ acc }&${ key }=${ baseRequestParams[key] }`;
        }, '');
        return await ctx.$axios.get(`${ API.main }/route`, {
            params: {
                url: `${ ctx.route.path }${ requestQueryStr }`
            }
        }).then(async (response) => {
            const langIso = (response?.data?.language ?? Helpers.getLocaleIso(ctx.app.i18n, ctx.app.i18n.locale));
            const localeCode = Helpers.getLocaleBeIso(ctx.app.i18n, langIso);
            ctx.$cookies.set('currentLangIso', langIso);
            ctx.$axios.defaults.headers['Accept-Language'] = (langIso || ' ');
            if (localeCode !== ctx.app.i18n.locale) {
                ctx.$cookies.set('preferenceLang', localeCode);
                ctx.$cookies.set('prevPreferenceLang', ctx.app.i18n.locale);
                await ctx.app.i18n.setLocale(localeCode);
            }
            return response.data;
        }).catch((e) => {
            if (e?.response?.status ?? null) {
                redirected = true;
                let firstCharter = e.response.data.url.includes('?') ? '&' : '?';
                let otherQueryStr = Object.keys(otherParams).reduce((acc, key, index) => {
                    return index === 0 ?
                        `${ acc }${firstCharter}${ key }=${ otherParams[key] }` :
                        `${ acc }&${ key }=${ otherParams[key] }`;
                }, '');
                ctx.redirect((e?.response?.data?.code ?? 302), e.response.data.url+otherQueryStr);
            }
        });
    })();

    // getLocalizeData if no data already set
    await getCombinedLocalizedData();

    if (redirected) {
        return;
    }

    const macroPageStructure = {};
    const pageType = (routeInfo?.type ?? 'unknown');
    const structure = (routeInfo?.structure ?? []);

    if (pageType === 'unknown' && structure.length < 1) {
        ctx.error({
            statusCode: 404,
            message: `Page not found`,
        });
        structure.push({
            name: 'page_404',
            type: 'page-block',
            data: [],
            layout: 'main',
            id: Math.round(Math.random() * 10)
        });
    }
    structure.forEach(function(item, index) {
        !(macroPageStructure.hasOwnProperty(item.type)) && (macroPageStructure[item.type] = []);
        macroPageStructure[item.type].push({
            orderOnPage: index,
            id: item.id,
            name: item.name,
            type: item.type,
        });
    });

    ctx.store.commit(`dynamicPage/${ types.SET_MACRO_STRUCTURE }`, {});
    ctx.store.commit(`dynamicPage/${ types.SET_MICRO_STRUCTURE }`, []);
    ctx.store.commit(`dynamicPage/${ types.SET_DYNAMIC_PAGE_LOADING }`, true);

    const microPageStructure = await (async function getMicroPageStructureData(macroStructure = {}) {
        const promises = {};
        const getWidgetsData = (widgetsData) => {
            return ctx.$axios.post(`${ API.main }/widgets`, widgetsData.map(function(item) {
                return item.id;
            })).then(function(response) {
                return widgetsData.map((item) => {
                    return Object.assign({}, item, { data: response.data[item.id] });
                });
            });
        };
        ctx.store.commit(`dynamicPage/${ types.SET_DYNAMIC_PAGE_LOADING }`, false);
        ctx.store.commit(`dynamicPage/${ types.SET_WIDGETS_DATA_LOADING }`, true);
        for (let key in macroStructure) {
            switch (key) {
                case 'widget':
                    promises['widget'] = getWidgetsData(macroStructure.widget);
                    break;
                default:
                    promises[key] = Promise.resolve(macroStructure[key]);
                    break;
            }
        }
        return (await Promise.all(Object.values(promises))).reduce(function(acc, blockTypeListData,) {
            return acc.concat(blockTypeListData);
        }, []);
    })(macroPageStructure);

    ctx.store.commit(`dynamicPage/${ types.SET_ROUTE_INFO }`, routeInfo);
    ctx.store.commit(`dynamicPage/${ types.SET_MACRO_STRUCTURE }`, macroPageStructure);
    ctx.store.commit(`dynamicPage/${ types.SET_MICRO_STRUCTURE }`, microPageStructure);

    // Get plp or pdp page data
    await (async function getProductData() {
        const getPdpData = async (url) => {
            let productData = null;

            if (process.client) {
                window.scrollTo(0, 0);
            }
            productData = await ctx.$axios.get(`${ API.catalog }/product${ url }`, {
                params: {
                    expand: AppConfig.store.PDP_Expand.join(',')
                }
            }).then(function(response) {
                const productInfo = response.data;
                ctx.app.$ga.sendProductPageOpen({
                    name: productInfo.displayName,
                    id: productInfo.id,
                    price: productInfo.price.sale,
                    brand: productInfo.division,
                    category: productInfo.productPath || [],
                    variant: 'adidas',
                });
                return productInfo;
            }).catch((e) => {
                if (e.response.status === 404) {
                    ctx.error({
                        statusCode: 404,
                        message: `Page not found`,
                    });
                    structure.push({
                        name: 'page_404',
                        type: 'page-block',
                        data: [],
                        layout: 'main',
                        id: Math.round(Math.random() * 10)
                    });
                }
            });

            ctx.store.commit(`productView/${ types.SET_SIZE_GUIDE_INFO }`, []);
            ctx.store.commit(`productView/${ types.SET_PRODUCT }`, productData);
        };

        const loadCatalogData = async (url, page, params = {}) => {
            const requestParams = Object.assign({}, baseRequestParams, {
                expand: AppConfig.store.catalogExpand.join(','),
                page: page,
                size: (ctx?.route?.query?.size ?? AppConfig.perPage),
                ...params
            });

            let catalogData = null;

            catalogData = await ctx.$axios.get(`${ API.catalog }/catalog${ url }`, {
                params: requestParams
            }).then(function(response) {
                return JSON.parse(JSON.stringify(response.data));
            }).catch((e) => {
                console.error(e);
            });

            ctx.store.commit(`catalogStore/${ types.SET_CATALOG_DATA }`, {});
            ctx.store.commit(`catalogStore/${ types.SET_CATALOG_DATA }`, JSON.parse(JSON.stringify(catalogData)));
            ctx.store.dispatch(`catalogStore/updateHistoryLinksAfterFiltersDataLoad`, JSON.parse(JSON.stringify(catalogData?.attributes?.selected ?? [])));
        };

        ctx.store.commit(`productView/${ types.SET_PRODUCT }`, null);
        ctx.store.commit(`productView/${ types.SET_SIZE_GUIDE_INFO }`, []);

        const emptyResultAction = (count) => {
            if (!count || count <= 0) {
                redirected = true;
                ctx.error({
                    statusCode: 404,
                    message: `Page not found`,
                    emptySearch: true
                });
            }
        };

        switch (pageType) {
            case 'plp':
                await loadCatalogData(ctx.route.path, (ctx?.route?.query?.page ?? 1));
                let catalogCount = ctx.store.getters['catalogStore/pageCountsInfo'];
                if (sParam.length > 0) {
                    emptyResultAction(catalogCount.totalCount);
                } else {
                    if (catalogCount.totalCount === 0) {
                        ctx.error({
                            statusCode: 404,
                            message: `Page not found`,
                        });
                    }
                }
                break;
            case 'pdp':
                await getPdpData(ctx.route.path);
                break;
        }
    })()
        .finally(() => {
            ctx.store.commit(`dynamicPage/${ types.SET_WIDGETS_DATA_LOADING }`, false);
        });

    async function getCombinedLocalizedData() {
        function hasLocalizeData() {
            return Boolean(
                (ctx.store.state.header.menu?.desktop?.length && ctx.store.state.header.menu?.mobile?.length) ||
                (ctx.store.state.header.topLinks?.length) ||
                (ctx.store.state.header.uspHeaderData?.length) ||
                (ctx.store.state.core.footerData?.desktop?.length && ctx.store.state.core.footerData?.mobile?.length)
            );
        }

        if (!hasLocalizeData()) {
            if (process.client) {
                await ctx.store.dispatch('getLocalizeData');
            } else {
                await (async function getLocalizeDataCached(ctx) {
                    const listApi = [
                        {
                            requestConfig: {
                                url: `${ API.main }/menu`,
                                method: 'get',// default
                            },
                            fileName: 'menu',
                            filePath: '/menu',
                            storeHandler: `header/${ types.SET_MENU_DATA }`,
                        },
                        {
                            requestConfig: {
                                url: `${ API.main }/header`,
                            },
                            fileName: 'header',
                            filePath: '/header',
                            storeHandler: `header/${ types.SET_HEADER_TOP_LINKS }`
                        },
                        {
                            requestConfig: {
                                url: `${ API.main }/usp-header`,
                            },
                            fileName: 'usp-header',
                            filePath: '/header',
                            storeHandler: `header/${ types.SET_USP_HEADER_ITEMS }`
                        },
                        {
                            requestConfig: {
                                url: `${ API.main }/footer`,
                            },
                            fileName: 'footer',
                            filePath: '/footer',
                            storeHandler: `core/${ types.SET_FOOTER_COLUMNS }`,
                        },
                    ];

                    const cachedApiPromises = listApi.map(async apiRoute => {
                        return await getServerCachedApiData(apiRoute, ctx).then(apiData => {
                            ctx.store.commit(apiRoute.storeHandler, apiData);
                            return apiData;
                        }).catch(error => {
                            console.error(error);
                            return error;
                        });
                    });

                    await Promise.allSettled(cachedApiPromises);
                })(ctx);
            }
        }
    }
}
